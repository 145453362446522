<template>
  <cs-page-user class="views-user-index">
    <div class="d-flex" style="height: 100%">
      <div class="d-flex flex-column f-left">
        <cs-panel-default
          class="my-product"
          title="我的产品"
          icon="icon-wodechanpin"
        >
          <div class="product-header">
            <div class="title">企业名称</div>
            <div class="name" :class="{ no: !formModel.companyName }">
              {{ formModel.companyName | defaultText("暂无") }}
            </div>
          </div>
          <div class="banner-content">
            <div class="title">
              {{ formModel.showType === 1 ? "在用产品" : "推荐产品" }}
            </div>
          </div>
          <MyProduct :list="formModel.products" />
        </cs-panel-default>

        <cs-panel-default
          class="question-panel"
          title="常见问题"
          icon="icon-wenti"
        >
          <CommonProblem />
        </cs-panel-default>
      </div>

      <div class="f-right">
        <cs-panel-default class="" title="产品动态" icon="icon-chanpindongtai">
          <ProductTrends />
        </cs-panel-default>
      </div>
    </div>
  </cs-page-user>
</template>

<script>
import { components } from "@/plugins/utils";
export default {
  ...components({
    "user/index": ["MyProduct", "CommonProblem", "ProductTrends"],
  }),
  data() {
    return {
      formModel: {
        companyName: "",
        companyId: "",
        showType: "",
        products: [],
      },
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.api.service
        .fpc_oapi_order_selectProductInUse({}, {})
        .then(async ({ code, data, msg }) => {
          await this.api.toast({ code, msg }, 0);
          this.formModel.companyName = data.companyName;
          this.formModel.companyId = data.companyId;
          this.formModel.showType = data.showType;
          this.formModel.products = data.products.map((ele) => {
            ele.showType = data.showType;
            return ele;
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.views-user-index {
  height: calc(100vh - 145px);
  .f-left {
    flex: 1;
    background-image: linear-gradient(
      185deg,
      #f6fcfb 0%,
      rgba(255, 255, 255, 0) 31%
    );
    .my-product {
      height: 520px;
      /deep/ .cs-panel-content {
        background-image: linear-gradient(
          185deg,
          #f6fcfb 0%,
          rgba(255, 255, 255, 0.445) 31%
        );
      }
      .product-header {
        padding: 20px 25px;
        .title {
          font-size: 14px;
          color: #333333;
          line-height: 40px;
        }

        .name {
          font-size: 18px;
          color: #000000;
          font-weight: bold;
        }

        .no {
          color: #999999;
        }
      }
      .banner-content {
        background: rgba(255, 255, 255, 0.31);
        .title {
          padding: 0 25px;
          font-size: 14px;
          color: #333333;
          line-height: 40px;
        }
      }
    }
    .question-panel {
      flex: 1;
      /deep/ .cs-panel-header {
        border-bottom: none !important;
        border-top: 1px solid #eee !important;
      }
    }
  }
  .f-right {
    width: 586px;
    @media screen and (max-width: 1440px) {
      width: 450px;
    }
    border-left: 1px solid #eee;
    /deep/ .cs-panel-content {
      background-image: linear-gradient(
        185deg,
        #f6fcfb 0%,
        rgba(255, 255, 255, 0) 31%
      );
      height: calc(100% - 57px);
      overflow: auto;
    }
  }
}
</style>